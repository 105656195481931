export const MAX_CONSTRUCTION_AGE_LIST = [
  ["1年以内", "1"],
  ["3年以内", "3"],
  ["5年以内", "5"],
  ["7年以内", "7"],
  ["10年以内", "10"],
  ["15年以内", "15"],
  ["20年以内", "20"],
  ["25年以内", "25"],
  ["30年以内", "30"],
  ["40年以内", "40"],
  ["指定しない", "100"],
];
