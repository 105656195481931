import "../Home/Home.css";

function NoMatch() {
  return (
    <div className="contents_box">
      <h1>このページは存在しません</h1>
    </div>
  );
}

export default NoMatch;
