import "../Contact/Contact.css";

function Home() {
  return (
    <div className="contents_box">
      <div className="home_block">
        <div class="box25">
          <article>
            <h1>運営者情報</h1>
            <p>当サイトは開発者個人が運営しているデモサイトです。</p>
            <p>
              掲載内容等に関しましてご要望などがございましたら、以下の宛先にご連絡ください。
            </p>
            <p>メールアドレス：sousoubaramos@gmail.com</p>
          </article>

          <article>
            <h1>プライバシーポリシー</h1>
            <p>
              ・個人情報の利用目的
              <br></br>
              当サイトでは、お問い合わせや記事へのコメントの際、名前やメールアドレス等の個人情報を入力いただく場合がございます。
              取得した個人情報は、お問い合わせに対する回答や必要な情報を電子メールなどでご連絡する場合に利用させていただくものであり、これらの目的以外では利用いたしません。
              <br></br>
              ・広告について
              <br></br>
              当ブログでは、第三者配信の広告サービス（Googleアドセンス、A8.net）を利用しており、ユーザーの興味に応じた商品やサービスの広告を表示するため、クッキー（Cookie）を使用しております。
              クッキーを使用することで当サイトはお客様のコンピュータを識別できるようになりますが、お客様個人を特定できるものではありません。
              Cookieを無効にする方法やGoogleアドセンスに関する詳細は「広告–ポリシーと規約–Google」をご確認ください。
              <br></br>
              ・アクセス解析ツールについて
              <br></br>
              当ブログでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。このGoogleアナリティクスはトラフィックデータの収集のためにクッキー（Cookie）を使用しております。トラフィックデータは匿名で収集されており、個人を特定するものではありません。
              コメントについて 当ブログへのコメントを残す際に、IP
              アドレスを収集しています。
              これはブログの標準機能としてサポートされている機能で、スパムや荒らしへの対応以外にこのIPアドレスを使用することはありません。
              なお、全てのコメントは管理人が事前にその内容を確認し、承認した上での掲載となります。あらかじめご了承ください。
            </p>
          </article>

          <article>
            <h1>免責事項</h1>
            <p>
              当ブログからのリンクやバナーなどで移動したサイトで提供される情報、サービス等について一切の責任を負いません。
              <br></br>
              また当ブログのコンテンツ・情報について、できる限り正確な情報を提供するように努めておりますが、正確性や安全性を保証するものではありません。情報が古くなっていることもございます。
              <br></br>
              当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
            </p>
          </article>

          <article>
            <h1>著作権について</h1>
            <p>
              当サイトで掲載している文章や画像などにつきましては、無断転載することを禁止します。
              <br></br>
              当サイトは著作権や肖像権の侵害を目的としたものではありません。著作権や肖像権に関して問題がございましたら、運営者情報ページよりご連絡ください。迅速に対応いたします。
            </p>
          </article>
        </div>
      </div>
    </div>
  );
}

export default Home;
