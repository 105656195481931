export const MIN_COST_LIST = [
  ["下限なし", "0"],
  ["3万円", "30000"],
  ["3.5万円", "35000"],
  ["4万円", "40000"],
  ["4.5万円", "45000"],
  ["5万円", "50000"],
  ["5.5万円", "55000"],
  ["6万円", "60000"],
  ["6.5万円", "65000"],
  ["7万円", "70000"],
  ["7.5万円", "75000"],
  ["8万円", "80000"],
  ["8.5万円", "85000"],
  ["9万円", "90000"],
  ["9.5万円", "95000"],
  ["10万円", "100000"],
  ["10.5万円", "105000"],
  ["11万円", "110000"],
  ["11.5万円", "115000"],
  ["12万円", "120000"],
  ["12.5万円", "125000"],
  ["13万円", "130000"],
  ["13.5万円", "135000"],
  ["14万円", "140000"],
  ["14.5万円", "145000"],
  ["15万円", "150000"],
  ["16万円", "160000"],
  ["17万円", "170000"],
  ["18万円", "180000"],
  ["19万円", "190000"],
  ["20万円", "200000"],
  ["22万円", "220000"],
  ["24万円", "240000"],
  ["26万円", "260000"],
  ["28万円", "280000"],
  ["30万円", "300000"],
  ["35万円", "350000"],
  ["40万円", "400000"],
  ["45万円", "450000"],
  ["50万円", "500000"],
  ["100万円", "1000000"],
];

export const MAX_COST_LIST = [
  ["3万円", "30000"],
  ["3.5万円", "35000"],
  ["4万円", "40000"],
  ["4.5万円", "45000"],
  ["5万円", "50000"],
  ["5.5万円", "55000"],
  ["6万円", "60000"],
  ["6.5万円", "65000"],
  ["7万円", "70000"],
  ["7.5万円", "75000"],
  ["8万円", "80000"],
  ["8.5万円", "85000"],
  ["9万円", "90000"],
  ["9.5万円", "95000"],
  ["10万円", "100000"],
  ["10.5万円", "105000"],
  ["11万円", "110000"],
  ["11.5万円", "115000"],
  ["12万円", "120000"],
  ["12.5万円", "125000"],
  ["13万円", "130000"],
  ["13.5万円", "135000"],
  ["14万円", "140000"],
  ["14.5万円", "145000"],
  ["15万円", "150000"],
  ["16万円", "160000"],
  ["17万円", "170000"],
  ["18万円", "180000"],
  ["19万円", "190000"],
  ["20万円", "200000"],
  ["22万円", "220000"],
  ["24万円", "240000"],
  ["26万円", "260000"],
  ["28万円", "260000"],
  ["30万円", "300000"],
  ["35万円", "350000"],
  ["40万円", "400000"],
  ["45万円", "450000"],
  ["50万円", "500000"],
  ["100万円", "1000000"],
  ["上限なし", "10000000"],
];
