const rosen_list = [
  {
    name: "東京メトロ東西線",
    stations: [
      { name: "中野", id: "ek_27280" },
      { name: "落合", id: "ek_06940" },
      { name: "高田馬場", id: "ek_22350" },
      { name: "早稲田", id: "ek_41750" },
      { name: "神楽坂", id: "ek_07710" },
      { name: "飯田橋", id: "ek_01820" },
      { name: "九段下", id: "ek_12720" },
      { name: "竹橋", id: "ek_22950" },
      { name: "大手町", id: "ek_06050" },
      { name: "日本橋", id: "ek_29710" },
      { name: "茅場町", id: "ek_09680" },
      { name: "門前仲町", id: "ek_39600" },
      { name: "木場", id: "ek_11770" },
      { name: "東陽町", id: "ek_25900" },
      { name: "南砂町", id: "ek_37570" },
      { name: "西葛西", id: "ek_28520" },
      { name: "葛西", id: "ek_07760" },
      { name: "浦安", id: "ek_04690" },
      { name: "南行徳", id: "ek_37450" },
      { name: "行徳", id: "ek_12030" },
      { name: "妙典", id: "ek_38520" },
      { name: "原木中山", id: "ek_31240" },
      { name: "西船橋", id: "ek_29360" },
    ],
  },

  {
    name: "東京メトロ半蔵門線",
    stations: [
      { name: "表参道", id: "ek_07240" },
      { name: "青山一丁目", id: "ek_00250" },
      { name: "永田町", id: "ek_27210" },
      { name: "半蔵門", id: "ek_31420" },
      { name: "九段下", id: "ek_12720" },
      { name: "神保町", id: "ek_20210" },
      { name: "大手町", id: "ek_06050" },
      { name: "三越前", id: "ek_36980" },
      { name: "水天宮前", id: "ek_20460" },
      { name: "清澄白河", id: "ek_50050" },
      { name: "住吉", id: "ek_20870" },
      { name: "錦糸町", id: "ek_12220" },
      { name: "押上", id: "ek_06820" },
    ],
  },
  {
    name: "東京メトロ有楽町線",
    stations: [
      { name: "和光市", id: "ek_41690" },
      { name: "地下鉄成増", id: "ek_04760" },
      { name: "地下鉄赤塚", id: "ek_04750" },
      { name: "平和台", id: "ek_34660" },
      { name: "氷川台", id: "ek_32630" },
      { name: "小竹向原", id: "ek_14950" },
      { name: "千川", id: "ek_21350" },
      { name: "要町", id: "ek_08710" },
      { name: "池袋", id: "ek_02060" },
      { name: "東池袋", id: "ek_31600" },
      { name: "護国寺", id: "ek_14760" },
      { name: "江戸川橋", id: "ek_04940" },
      { name: "市ヶ谷", id: "ek_02980" },
      { name: "麹町", id: "ek_14020" },
      { name: "桜田門", id: "ek_16170/nj_215" },
      { name: "有楽町", id: "ek_40650" },
      { name: "銀座一丁目", id: "ek_12210" },
      { name: "新富町", id: "ek_19980" },
      { name: "月島", id: "ek_24650" },
      { name: "豊洲", id: "ek_26540" },
      { name: "辰巳", id: "ek_23180" },
      { name: "新木場", id: "ek_19330" },
    ],
  },

  {
    name: "東京メトロ丸ノ内線",
    stations: [
      { name: "荻窪", id: "ek_06640" },
      { name: "南阿佐ケ谷", id: "ek_37270" },
      { name: "新高円寺", id: "ek_19470" },
      { name: "東中野", id: "ek_32110" },
      { name: "方南町", id: "ek_34900" },
      { name: "中野富士見町", id: "ek_27380" },
      { name: "中野新橋", id: "ek_27360" },
      { name: "中野坂上", id: "ek_27320" },
      { name: "西新宿", id: "ek_28860" },
      { name: "新宿", id: "ek_19670" },
      { name: "新宿三丁目", id: "ek_19690" },
      { name: "新宿御苑前", id: "ek_19680" },
      { name: "四谷三丁目", id: "ek_41170" },
      { name: "四ツ谷", id: "ek_41160" },
      { name: "赤坂見附", id: "ek_00320" },
      { name: "国会議事堂前", id: "ek_15020" },
      { name: "霞ヶ関", id: "ek_08300" },
      { name: "銀座", id: "ek_12200" },
      { name: "東京", id: "ek_25620" },
      { name: "大手町", id: "ek_06050" },
      { name: "淡路町", id: "ek_01720" },
      { name: "御茶ノ水", id: "ek_06970" },
      { name: "本郷三丁目", id: "ek_35340" },
      { name: "後楽園", id: "ek_14430" },
      { name: "茗荷谷", id: "ek_38430" },
      { name: "新大塚", id: "ek_19160" },
      { name: "池袋", id: "ek_02060" },
    ],
  },
  {
    name: "東京メトロ銀座線",
    stations: [
      { name: "渋谷", id: "ek_17640" },
      { name: "表参道", id: "ek_07240" },
      { name: "外苑前", id: "ek_07450" },
      { name: "青山一丁目", id: "ek_00250" },
      { name: "赤坂見附", id: "ek_00320" },
      { name: "溜池山王", id: "ek_23660" },
      { name: "虎ノ門", id: "ek_26650" },
      { name: "新橋", id: "ek_20110" },
      { name: "銀座", id: "ek_12200" },
      { name: "京橋", id: "ek_12060" },
      { name: "日本橋", id: "ek_29710" },
      { name: "三越前", id: "ek_36980" },
      { name: "神田", id: "ek_10270" },
      { name: "末広町", id: "ek_20510" },
      { name: "上野広小路", id: "ek_04070" },
      { name: "上野", id: "ek_04030" },
      { name: "稲荷町", id: "ek_03430" },
      { name: "田原町", id: "ek_23730" },
      { name: "浅草", id: "ek_00670" },
    ],
  },
  {
    name: "東京メトロ千代田線",
    stations: [
      { name: "代々木上原", id: "ek_41290" },
      { name: "代々木公園", id: "ek_41300" },
      { name: "明治神宮前〈原宿〉", id: "ek_39010" },
      { name: "表参道", id: "ek_07240" },
      { name: "乃木坂", id: "ek_30010" },
      { name: "赤坂", id: "ek_00300" },
      { name: "国会議事堂前", id: "ek_15020" },
      { name: "霞ヶ関", id: "ek_08300" },
      { name: "日比谷", id: "ek_33040" },
      { name: "二重橋前〈丸の内〉", id: "ek_29480" },
      { name: "大手町", id: "ek_06050" },
      { name: "新御茶ノ水", id: "ek_19190" },
      { name: "湯島", id: "ek_40710" },
      { name: "根津", id: "ek_29860" },
      { name: "千駄木", id: "ek_21530" },
      { name: "西日暮里", id: "ek_29160" },
      { name: "町屋", id: "ek_35790" },
      { name: "北千住", id: "ek_11310" },
      { name: "綾瀬", id: "ek_01370" },
      { name: "北綾瀬", id: "ek_10900" },
    ],
  },

  {
    name: "東京メトロ日比谷線",
    stations: [
      { name: "中目黒", id: "ek_27580" },
      { name: "恵比寿", id: "ek_05050" },
      { name: "広尾", id: "ek_33410" },
      { name: "六本木", id: "ek_41560" },
      { name: "神谷町", id: "ek_09450" },
      { name: "虎ノ門ヒルズ", id: "ek_84580" },
      { name: "霞ヶ関", id: "ek_08300" },
      { name: "日比谷", id: "ek_33040" },
      { name: "銀座", id: "ek_12200" },
      { name: "東銀座", id: "ek_31870" },
      { name: "築地", id: "ek_24620" },
      { name: "八丁堀", id: "ek_30820" },
      { name: "茅場町", id: "ek_09680" },
      { name: "人形町", id: "ek_29780" },
      { name: "小伝馬町", id: "ek_15070" },
      { name: "秋葉原", id: "ek_00480" },
      { name: "仲御徒町", id: "ek_26940" },
      { name: "上野", id: "ek_04030" },
      { name: "入谷", id: "ek_03760" },
      { name: "三ノ輪", id: "ek_38130" },
      { name: "南千住", id: "ek_37600" },
      { name: "北千住", id: "ek_11310" },
    ],
  },
  {
    name: "東京メトロ南北線",
    stations: [
      { name: "目黒", id: "ek_39110" },
      { name: "白金台", id: "ek_18930" },
      { name: "白金高輪", id: "ek_18940" },
      { name: "麻布十番", id: "ek_00800" },
      { name: "六本木一丁目", id: "ek_41570" },
      { name: "溜池山王", id: "ek_23660" },
      { name: "永田町", id: "ek_27210" },
      { name: "四ツ谷", id: "ek_41160" },
      { name: "市ヶ谷", id: "ek_02980" },
      { name: "飯田橋", id: "ek_01820" },
      { name: "後楽園", id: "ek_14430" },
      { name: "東大前", id: "ek_25730" },
      { name: "本駒込", id: "ek_35370" },
      { name: "駒込", id: "ek_15330" },
      { name: "西ケ原", id: "ek_28560" },
      { name: "王子", id: "ek_05180" },
      { name: "王子神谷", id: "ek_05830" },
      { name: "志茂", id: "ek_17810" },
      { name: "赤羽岩淵", id: "ek_00390" },
    ],
  },
  {
    name: "東京メトロ副都心線",
    stations: [
      { name: "和光市", id: "ek_41690" },
      { name: "地下鉄成増", id: "ek_04760" },
      { name: "地下鉄赤塚", id: "ek_04750" },
      { name: "平和台", id: "ek_34660" },
      { name: "氷川台", id: "ek_32630" },
      { name: "小竹向原", id: "ek_14950" },
      { name: "千川", id: "ek_21350" },
      { name: "要町", id: "ek_08710" },
      { name: "池袋", id: "ek_02060" },
      { name: "雑司が谷", id: "ek_80845" },
      { name: "西早稲田", id: "ek_80840" },
      { name: "東新宿", id: "ek_50015" },
      { name: "新宿三丁目", id: "ek_19690" },
      { name: "北参道", id: "ek_80835" },
      { name: "明治神宮前〈原宿〉", id: "ek_39010" },
      { name: "渋谷", id: "ek_17640" },
    ],
  },
];

export default rosen_list;
